html,
body {
	width: 100%;
	height: 100%;
}

body {
	background-image: url(../images/idea.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center; 
	overflow-y: hidden;
}

.downloadsPage {
	background-image: url(../images/itali.jpg);
}

.projectsPage {
	background-image: url(../images/slovenia.jpg);
}

.aboutPage {
	background-image: url(../images/pineapple.jpg);
}

#root .MuiPaper-root {
	background-color: rgba(255,255,255,0.5)
}

#root > .MuiContainer-maxWidthLg {
	height: calc(100vh - 32px);
	overflow: auto;
}
